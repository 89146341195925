<template>
    <div class="office-fields">
        <div class="row">
            <div class="col-6">
                <mercur-input data-test="locationOfficeLocationName"
                    v-model="v.locationName.$model"
                    required
                    :class="{'form-invalid': v.locationName.$error}">
                    {{locationType === 'OFFICE' ? 'Office name':'Facility name'}}
                    <template slot="note">
                        <span class="form-error" v-if="!v.locationName.required">Location name is required</span>
                    </template>
                </mercur-input>
                <input data-test="locationOfficeLocationType" type="hidden" name="location.locationType" :value="locationType" />
            </div>

            <div class="col-6">
                <mercur-select data-test="locationOfficeLocationStatus" v-model="location.locationStatus" required>
                    <template slot="label">Status</template>
                    <option v-for="(status, key) in locationStatusses" :value="status.value" :key="key">{{status.title}}</option>
                </mercur-select>
            </div>

            <div class="col-12">
                <mercur-input data-test="locationOfficeEmail"
                    v-model="v.emailAddress.$model"
                    required
                    :class="{'form-invalid':  v.emailAddress.$error}">
                    E-mail
                    <template slot="note">
                        <span class="form-error" v-if="!v.emailAddress.required">Email address is required</span>
                    </template>
                    <template slot="note">
                        <span class="form-error" v-if="!v.emailAddress.email">Email address is not valid</span>
                    </template>
                </mercur-input>
            </div>

            <div class="col-6">
                <mercur-select data-test="locationOfficeCountryName" v-model="countryName" v-on:keypress.enter.prevent>
                    <template slot="label">Select a country *</template>
                    <option
                        v-for="(country, key) in availableCountries"
                        :key="key"
                        :value="country.countryName">
                        {{country.countryName}}
                    </option>
                    <template slot="note">
                        <span class="form-error" v-if="v.country.$error && !v.country.required">Country is required</span>
                    </template>
                </mercur-select>
                <input data-test="locationOfficeState"  type="hidden" name="location.state" :value="'STATE'" />
            </div>

            <div class="col-6">
                <mercur-select data-test="locationOfficeTimezone" v-model="v.$model.facilitySettings.timezone" :required="true">
                    <template slot="label">Timezone</template>
                    <option
                        v-for="(timezone, key) in availableTimezones"
                        :key="key"
                        :value="timezone.tzCode">
                        {{timezone.label}}
                    </option>
                </mercur-select>
            </div>
            <div class="col-6 mt-3">
                <mercur-input data-test="locationOfficeAddress"
                    v-model="v.address.$model"
                    required
                    :class="{'form-invalid': v.address.$error}">
                    Address
                    <template slot="note">
                        <span class="form-error" v-if="!v.address.required">Address is required</span>
                    </template>
                </mercur-input>
            </div>
            <div class="col-6 mt-3">
                <mercur-input data-test="locationOfficeAddressAditional" v-model="location.addressAdditional">
                    Address additional
                </mercur-input>
            </div>

            <div class="col-6">
                <mercur-input data-test="locationOfficeHouseNumber"
                    v-model="v.houseNumber.$model"
                    type="number"
                    required
                    :class="{'form-invalid': v.houseNumber.$error}">
                    House number
                    <template slot="note">
                        <span class="form-error" v-if="!v.houseNumber.required">House number is required</span>
                    </template>
                </mercur-input>
            </div>

            <div class="col-6">
                <mercur-input data-test="locationOfficeHouseNumberAdditional" v-model="location.houseNumberAdditional">
                    House number additional
                </mercur-input>
            </div>

            <div class="col-4">
                <mercur-input data-test="locationOfficeZipCode"
                    v-model="v.zipCode.$model"
                    required
                    :class="{'form-invalid': v.zipCode.$error}">
                    Zip code
                    <template slot="note">
                        <span class="form-error" v-if="!v.zipCode.required">Zip code is required</span>
                    </template>
                </mercur-input>
            </div>

            <div class="col-12">
                <mercur-input data-test="locationOfficeCity"
                    v-model="v.city.$model"
                    required
                    :class="{'form-invalid': v.city.$error}">
                    City
                    <template slot="note">
                        <span class="form-error" v-if="!v.city.required">City is required</span>
                    </template>
                </mercur-input>
            </div>

            <div class="col-12">
                <mercur-input data-test="locationOfficePhone" v-model="location.phone" type="phone">
                    Phone number
                </mercur-input>
            </div>

            <div class="col-12" v-if="locationType === 'OFFICE'">
                <pretty-select data-test="locationOfficeCertifications"
                    placeholder="Certifications"
                    id="certifications"
                    name="certifications"
                    :multiple="true"
                    v-model="location.certifications"
                    v-on:keypress.enter.prevent
                    :options="availableCertifications"
                    label="Certifications"
                ></pretty-select>
            </div>
            <div class="col-12">
                <mercur-input data-test="locationOfficeComplaintsSlaHours"
                    v-model.number="complaintsSlaHours"
                    type="number"
                    step="1">
                    Complaint SLA time
                    <div slot="suffix">hours</div>
                </mercur-input>
                <span v-if="locationType === 'OFFICE'" class="text-secondary">This can be overwritten on the facility level</span>
            </div>
        </div>
    </div>
</template>

<script>
import regions from 'country-region-data'
import timezones from 'timezones-list'
import CONFIG from '@root/config'
import { getDeepProperty } from './utils/Utils'
import PrettySelect from './utils/PrettySelect'

export default {
    name: 'LocationOfficeFields',
    components: { PrettySelect },
    props: {
        location: Object,
        locationType: String,
        setDone: null,
        showContinueButton: {
            type: Boolean,
            default: true,
        },
        v: {},
    },
    data () {
        return {
            availableCountries: regions,
            availableTimezones: timezones.sort((a, b) => a.label.localeCompare(b.label)),
            availableCertifications: ['ISO 9001', 'ISO 12647', 'FSC', 'PEFC'],
        }
    },
    computed: {
        locationStatusses () {
            return CONFIG.STATUSSES.SUPPLIERS.LOCATION
        },
        countryName: {
            get () {
                return this.location.country
            },
            set (value) {
                this.location.country = value
            },
        },
        complaintsSlaHours: {
            get () {
                return getDeepProperty('location.settings.complaints.slaHours', this)
            },
            set (value) {
                if (!this.location || !this.location.settings) {
                    this.$set(this.location, 'settings', {
                        complaints: {
                            slaHours: value,
                        },
                    })
                    return
                }
                if (!this.location.settings.complaints) {
                    this.$set(this.location.settings, 'complaints', {
                        slaHours: value,
                    })
                    return
                }
                this.$set(this.location.settings.complaints, 'slaHours', value)
            },
        },
    },
}
</script>

<style scoped>

</style>
